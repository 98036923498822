<template>
  <div class="templete-manage">
    <a-spin :spinning="spinning">
      <!-- <react-table
        ref="listTable"
        :table-data="tableData"
        :total-items="totalItems"
        :showTotal="showTotalFun"
        :actions-type="actionsType"
        :columns="columns"
        :hide-row-selection="true"
        :current-page="pageNumber"
        :is-full="true"
        :scroll="{ x: 0 }"
        @emitRefreshData="clearFilterAndRefresh"
        @emitSortData="updateSortData"
        @emitSelectChange="updateSelectChange"
        @emitOnPageChange="updatePageChange"
        @emitShowSizeChange="updateShowSizeChange"
      > -->
      <ele-table
        :columns="columns"
        :table-data="tableData"
        :total-items="totalItems"
        :is-full="true"
        :actionsType="actionsType"
        :current-page="pageNumber"
        :hide-row-selection="true"
        @emitRefreshData="clearFilterAndRefresh"
        @emitSelectChange="updateSelectChange"
        @emitOnPageChange="updatePageChange"
        @emitShowSizeChange="updateShowSizeChange"
      >
        <a-row align="middle" :gutter="8">
        <a-col :span="4">
            <a-input
              v-model="params.projectName"
              placeholder="请选择项目"
              @click="selectProjectModal"
            >
            </a-input>
            <a-input v-model="params.projectIds" type="hidden">
            </a-input>
          </a-col>
          <a-col :span="4">
            <a-input
              allowClear
              v-model.trim="params.agreementTemplateName"
              :placeholder="l('请输入协议名称')"
            />
          </a-col>
         <!-- <a-col :span="4">
            <a-input
              allowClear
              v-model.trim="params.projectName"
              :placeholder="l('请输入项目名称')"
            />
          </a-col>-->
          <a-col :span="3">
            <a-select
              style="width: 100%"
              @change="standardChange"
              placeholder="请选择类型"
              v-model="params.isStandard"
              allowClear
            >
              <a-select-option
                :value="it.value"
                v-for="(it, i) in standardOption"
                :key="i"
              >
                {{ it.label }}
              </a-select-option>
            </a-select>
          </a-col>
          <a-col :span="3">
            <a-select
              style="width: 100%"
              @change="statusChange"
              placeholder="请选择状态"
              v-model="params.agreementTemplateStatus"
              allowClear
            >
              <a-select-option
                :value="it.value"
                v-for="(it, i) in statusOption"
                :key="i"
              >
                {{ it.label }}
              </a-select-option>
            </a-select>
          </a-col>
          <a-col :span="3">
            <a-select
              style="width: 100%"
              placeholder="请选择分组"
              v-model="params.agreementGroupType"
              allowClear
            >
              <a-select-option
                :value="it.value"
                v-for="(it, i) in statusGroupOption"
                :key="i"
              >
                {{ it.label }}
              </a-select-option>
            </a-select>
          </a-col>
          <a-col :span="4">
            <a-button
              type="primary"
              @click="search"
              v-if="
                isGranted('Pages.UnnaturalAgreementTemplatePermissions.Query')
              "
            >
              {{ l("Search") }}
            </a-button>
            <a-button @click="clearFilterAndRefresh">{{ l("Reset") }}</a-button>
          </a-col>
          <a-col :span="3" style="text-align: right">
            <a-button
              type="primary"
              @click="goAddTemplete('create')"
              v-if="
                isGranted('Pages.UnnaturalAgreementTemplatePermissions.Create')
              "
            >
              添加协议模版
            </a-button>
          </a-col>
        </a-row>
      </ele-table>
    </a-spin>
  </div>
</template>


<script>
import { AppComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import { CommonServiceProxy } from "@/shared/common-service";
import moment from "moment";
import EleTable from "@/components/ele-table";
import ReactModify from "@/components/react-modify";
import { AppConsts } from "@/abpPro/AppConsts";
import SelectPageList from "@/components/linggong/select-page-list";

export default {
  mixins: [AppComponentBase],
  components: {
    EleTable,
  },
  data() {
    return {
      spinning: false,
      commonService: null,
      params: {
        filterText: "",
        agreementTemplateName: "",
        projectName: "",
        projectIds:"",
        agreementTemplateStatus: undefined,
        agreementGroupType: undefined,
        isStandard: undefined,
      },
      // 总数
      totalItems: 0,
      // 当前页码
      pageNumber: 1,
      // 共多少页
      totalPages: 1,
      // 条数显示范围
      pagerange: [1, 1],
      // 显示条数
      pageSizeOptions: ["10", "20", "30", "40"],
      request: { sorting: "", maxResultCount: 50, skipCount: 0 }, //sorting {排序项 ASC/DESC}
      actionsType: {},
      columns: [],
      tableData: [],
      standardOption: [
        { label: "全部", value: null },
        { label: "标准版", value: 1 },
        { label: "非标准版", value: 0 },
      ],
      statusOption: [
        { label: "全部", value: null },
        { label: "启用", value: 1 },
        { label: "禁用", value: 0 },
      ],
      statusGroupOption: [
        { label: "未分组", value: 0 },
        { label: "合同协议", value: 1 },
        { label: "证明文件", value: 2 },
      ],
    };
  },
  created() {
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
    this.initActionsType();
    this.initColums();
    this.getData();
  },
  mounted() {},
  methods: {
    selectProjectModal() {
      ModalHelper.create(
        SelectPageList,
        {
          selectType: "checkbox", // checkbox radio
          title: "选择项目", // 显示标题
          url: "/api/services/app/UnnaturalPersonProject/GetSelectPaged", // 数据接口URL
          searchPlaceholder: "项目名称/项目代码",
          columnsData: [
            {
              title: "项目名称",
              dataIndex: "name",
              key: "name",
            },
            {
              title: "项目代码",
              dataIndex: "description",
              key: "description",
            },
          ],
        },
        {
          isChange: true,
          width: "860px",
          heigth: "650px",
        }
      ).subscribe((res) => {
        console.log(res);
        const { success, data } = res;
        if (success) {
          //   message.success("succed");
          //   console.log(data);
          let projectIds = "";
          let projectNames = "";
          for (let i = 0; i < data.length; i += 1) {
            const item = data[i];
            if (i === data.length - 1) {
              projectIds += item.id;
              projectNames += item.name;
              continue;
            }
            projectIds += item.id + ",";
            projectNames += item.name + ",";
          }
          this.params.projectIds = projectIds; //data.id;
          this.params.projectName = projectNames; //data.name;
          this.getData();
          // this.getTaskByProjectId(0, data.id);
        }
      });
    },
    standardChange(val) {
      console.log(val);
    },
    statusChange(val) {
      console.log(val);
    },
    initColums() {
      let _this = this;
      this.columns = [
        {
          title: this.l("模板名称"),
          dataIndex: "agreementTemplateName",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "agreementTemplateName" },
          ellipsis: true,
        },
        {
          title: this.l("项目名称"),
          dataIndex: "projectName",
          sorter: false,
          // width: 250,
          align: "center",
          scopedSlots: { customRender: "projectName" },
        },
        {
          title: this.l("协议分组"),
          dataIndex: "agreementGroupTypeName",
          sorter: false,
          // width: 250,
          align: "center",
          scopedSlots: { customRender: "agreementGroupTypeName" },
        },
        {
          title: this.l("开始时间"),
          dataIndex: "beginDateDesc",
          sorter: false,
          // width: 120,
          align: "center",
          scopedSlots: { customRender: "beginDate" },
        },
        {
          title: this.l("结束时间"),
          dataIndex: "endDateDesc",
          sorter: false,
          // width: 120,
          align: "center",
          scopedSlots: { customRender: "endDate" },
        },
        {
          title: this.l("状态名称"),
          dataIndex: "status",
          sorter: true,
          // width: 120,
          align: "center",
          type: 'tag',
          scopedSlots: { customRender: "status" },
          customRender: function (text, record, index) {
            if (text == "Enabled") {
              return "启用";
            } else {
              return "禁用";
            }
          },
        },
        {
          title: this.l("是否标准模板"),
          dataIndex: "isStandard",
          sorter: true,
          // width: 130,
          align: "center",
          scopedSlots: { customRender: "isStandard" },
          customRender: function (text, record, index) {
            if (text == true) {
              return "标准版";
            } else {
              return "非标准版";
            }
          },
        },
        {
          title: this.l("Actions"),
          dataIndex: "actions",
          fixed: "right",
          sorter: false,
          width: 150,
          align: "center",
          // width: "100",
          scopedSlots: { customRender: "actions" },
        },
      ];
    },
    search() {
      this.pageNumber = 1
      this.request.skipCount = 0
      this.getData()
    },
    async getData() {
      this.spinning = true;
      const opts = {
        ...this.params,
        isStandard:
          this.params.isStandard === 1
            ? true
            : this.params.isStandard === 0
            ? false
            : "",
        projectName:"",
        sorting: this.request.sorting,
        maxResultCount: this.request.maxResultCount,
        skipCount: this.request.skipCount,
      };
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/UnnaturalPersonProjectAgreement/GetPaged",
          params: opts,
        });
        this.tableData = res.items.map((item) => {
          return {
            ...item,
            beginDateDesc: item.beginDate
              ? moment(item.beginDate).format("YYYY-MM-DD")
              : "-",
            endDateDesc: item.endDate
              ? moment(item.endDate).format("YYYY-MM-DD")
              : "-",
            isStandardDesc: item.isStandard ? "是" : "否",
            isStandard: item.isStandard ? '标准版' : '非标准版',
            status: item.agreementTemplateStatus ? "启用" : "禁用",
            statusColor: item.agreementTemplateStatus ? '#87d068' : '#f50',
          };
        });
        this.totalItems = res.totalCount;
        this.pagerange = [
          (this.pageNumber - 1) * this.request.maxResultCount + 1,
          this.pageNumber * this.request.maxResultCount,
        ];
        this.totalPages = Math.ceil(
          res.totalCount / this.request.maxResultCount
        );
        this.spinning = false;
      } catch (error) {
        console.log(error);
        this.spinning = false;
      }
    },
    // 清除条件并刷新
    clearFilterAndRefresh() {
      this.params = {
        filterText: "",
        agreementTemplateName: "",
        projectName: "",
        projectIds:"",
        agreementTemplateStatus: undefined,
        agreementGroupType: undefined,
        isStandard: undefined,
      };
      this.request = { sorting: "", maxResultCount: 50, skipCount: 0 };
      this.getData();
    },
    // 分页事件
    showTotalFun() {
      return this.l(
        "GridFooterDisplayText",
        this.pageNumber,
        this.totalPages,
        this.totalItems,
        this.pagerange[0],
        this.pagerange[1]
      );
    },
    // 排序更新data
    updateSortData(newV) {
      let { columnKey, order } = newV;
      this.request.sorting = order ? `${columnKey} ${order}` : "";
      this.getData();
    },
    // 分页
    updatePageChange(newV) {
      let { page, pageSize } = newV;
      this.pageNumber = page;
      this.request.skipCount = (page - 1) * this.request.maxResultCount;
      this.getData();
    },
    updateShowSizeChange(newV) {
      let { current, size } = newV;
      this.pageNumber = 1;
      this.request.maxResultCount = size;
      this.getData();
    },
    // table选择事件
    updateSelectChange(newV) {
      let { selectedRowKeys, selectedRows } = newV;
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    // 初始化需要显示的func btn
    initActionsType() {
      let _this = this;
      let obj = {
        type: "",
        isShow: true,
        fns: {
          dropList: [
            {
              granted: this.isGranted(
                "Pages.UnnaturalAgreementTemplatePermissions.Preview"
              ),
              name: this.l("预览"),
              icon: "edit",
              fn: (data) => {
                _this.previewItem(data);
              },
            },
          ],
          delete: {
            granted: this.isGranted(
              "Pages.UnnaturalAgreementTemplatePermissions.Stop"
            ),
            name: this.l("禁/启用"),
                tipsDesc: "禁/启用协议模板?",
            fn: (data) => {
              _this._changeStatus(data.agreementTemplateId)
            },
          },
        },
      };
      this.actionsType = obj;
    },
    goAddTemplete(id) {
      this.$router.push({
        path: `/app/fs/unAddTemplete/${id}`,
        // path: `/app/fs/addTemplete4Steps/${id}`,
      });
    },
    goSignMethod(id) {
      this.$router.push({
        path: `/app/fs/unSignMethod/d5da7338-95fb-4217-98e8-08d8eddc785b/0`,
      });
    },
    previewItem(item) {
      const { pdfTemplateUrl } = item;
      window.open(`${AppConsts.uploadBaseUrl}${pdfTemplateUrl}`);
    },
        async _changeStatus(id) {
      this.spinning = true;
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/UnnaturalPersonAgreementTemplate/StatusChange",
          params: {id:id},
        });
           this.getData();
        this.$notification["success"]({
          message: this.l("更新状态成功"),
        });
        this.spinning = false;
      } catch (error) {
        this.spinning = false;
      }
    },
  },
};
</script>

<style scoped lang="less">
.templete-manage {
  // min-height: 600px;
}
</style>